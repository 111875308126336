import api from '@api'
import Constants from '@constants'
import { transformTicketForDetail } from '@data/ticket'
import { transformAssetForDetail } from '@data/asset'
import { transformMyApproval, transformApprover } from '@data/approval'
import { transformKnowledge } from '@data/knowledge'
import { transformPurchaseForDetail } from '@data/purchase'
export function getMyApprovalsApi(
  isPublic,
  qualifications,
  limit,
  offset,
  sortedColumn
) {
  return api
    .post(
      `${isPublic ? '/cportal' : ''}/myapproval/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformMyApproval),
        total: data.totalCount,
      }
    })
}
export function getApproverApi(id, isPublic = false, additionalUrlParams = {}) {
  return api
    .get(`${isPublic ? '/cportal' : ''}/approver/${id}`, {
      params: {
        ...additionalUrlParams,
      },
    })
    .then(transformApprover)
}

export function getApprovalResourceDetailApi(moduleName, id, isPublic) {
  const apiSignature = isPublic
    ? `/cportal/${moduleName}/${id}`
    : `/${moduleName}/ref/approval/${id}`
  return api.get(apiSignature).then((data) => {
    if (
      [
        Constants.ASSET,
        Constants.ASSET_HARDWARE,
        Constants.ASSET_NON_IT,
        Constants.ASSET_CONSUMABLE,
        Constants.ASSET_FIELD_TRANSITION_HISTORY,
      ].indexOf(moduleName) >= 0
    ) {
      return transformAssetForDetail(data)
    } else if ([Constants.KNOWLEDGE].indexOf(moduleName) >= 0) {
      return transformKnowledge(data)
    } else if ([Constants.PURCHASE].indexOf(moduleName) >= 0) {
      return transformPurchaseForDetail(data)
    } else {
      return transformTicketForDetail(data)
    }
  })
}

export function getRequestChangeApprovalDetailApi(moduleName, id, isPublic) {
  const apiSignature = isPublic
    ? `/cportal/approval/${moduleName}/${id}`
    : `/${moduleName}/ref/approval/${id}`
  return api.get(apiSignature).then((data) => {
    return transformTicketForDetail(data)
  })
}

export function getApprovalById(id) {
  return api.get(`/public/myapproval/${id}`)
}
