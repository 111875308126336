<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
import { LicenseComputed } from '@state/modules/license'
export default {
  name: 'ItInfrastructureModule',
  page() {
    return {
      title: this.$tc('support_channel', 2),
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.path.indexOf('chat') >= 0) {
      // vm is this
      next((vm) => {
        if (
          vm.availableModulesInLicense.indexOf(vm.$constants.REQUEST) === -1
        ) {
          return vm.$router.replace({ name: 'upgrade-plan' })
        }
        return true
      })
    }
    if (to.path.indexOf('ai-bot') >= 0) {
      // vm is this
      next((vm) => {
        if (
          vm.availableModulesInLicense.indexOf(vm.$constants.REQUEST) === -1
        ) {
          return vm.$router.replace({ name: 'upgrade-plan' })
        }
        return true
      })
    }
    next()
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
  },
  created() {
    if (
      this.$route.path.indexOf('ldap-configurations') >= 0 ||
      this.$route.path.indexOf('sso-configuration') >= 0
    ) {
      if (this.myAllowedModules.indexOf('admin.users') === -1) {
        return this.$router.replace({ name: '404' })
      }
    } else if (this.myAllowedModules.indexOf('admin.support_channels') === -1) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
}
</script>
