<template>
  <FlotoFixedView>
    <FlotoPageHeader
      :back-link="
        backLink ||
        $currentModule.getRoute('request', {
          params: {
            ...($route.params.searchTerm
              ? { searchTerm: $route.params.searchTerm }
              : {}),
          },
        })
      "
      class="px-4"
      use-divider
      :title="service.name || `${$tc('request')} ${$tc('service')}`"
    >
      <template v-slot:back-button>
        <slot name="back-button"></slot>
      </template>
    </FlotoPageHeader>
    <div class="min-h-0 mt-3">
      <FlotoContentLoader :loading="loading" class="h-100">
        <FormRulesProvider
          :key="service.id"
          :module-name="$constants.SERVICE_CATALOG"
          :parent-resource-id="service.id"
          :custom-fields="serviceFormFields"
        >
          <MRow class="w-full min-h-0">
            <MCol auto-size>
              <div class="pl-6 pr-4">
                <img :src="service.image" width="150" height="150" />
              </div>
            </MCol>
            <MCol class="flex flex-col h-100 flex-1">
              <FlotoScrollView>
                <h4 class="text-primary font-semibold">{{ service.name }}</h4>
                <div
                  v-if="
                    (!isPortalLogin && service.amount > 0) ||
                    (isPortalLogin && service.showInCustomerPortalAmount)
                  "
                  class="text-neutral flex"
                >
                  {{ $tc('cost') }}: {{ service.amount }}
                  <CurrencyPicker
                    v-model="service.currencyId"
                    text-only
                    disabled
                  />
                </div>
                <div class="flex flex-col">
                  {{ service.serviceDescription }}
                </div>
                <template v-if="serviceFormFields.length">
                  <MDivider />
                  <h5 class="text-primary">{{ $t('additional_info') }}</h5>
                </template>
                <component
                  :is="useFormHelpterProvider ? 'FormHelperProvider' : 'div'"
                >
                  <FormConsumer
                    v-if="serviceFormFields.length"
                    ref="formRef"
                    v-model="request"
                    :with-submit="withSubmit"
                    :form-fields="serviceFormFields"
                    :module-name="$constants.SERVICE_CATALOG"
                    :external-data="{
                      requestType: 'service_request',
                      serviceCatalogId: $route.params.id,
                    }"
                    apply-form-rules
                    allow-field-mapping
                    :service-catalog-field-mapping="serviceCatalogFieldMapping"
                    :service-catalog-id="$route.params.id"
                    @change="$emit('change', $event)"
                    @submit="handleFormSubmitted"
                  >
                    <template v-slot:submit>
                      <span />
                    </template>
                    <template v-slot:reset>
                      <span />
                    </template>
                  </FormConsumer>
                </component>
                <LinkAssetDrawer
                  v-if="service.allowRequesterToLinkMyAsset"
                  :module-name="$constants.ASSET"
                  :default-selected-assets="selectedAssets"
                  :service-catalog-id="$route.params.id"
                  class="ml-2"
                  @change="handleLinkAssetSelectionChange"
                />
                <MDivider v-if="withSubmit" />
                <div v-if="withSubmit" class="flex justify-end mb-4">
                  <MButton
                    id="submit-request"
                    :loading="processing"
                    class="mx-1"
                    @click="submitForm"
                  >
                    {{ $t('submit_request') }}
                  </MButton>
                  <FlotoLink
                    class="ml-4"
                    :to="backLink || $currentModule.getRoute('request')"
                    variant="default"
                    as-button
                  >
                    {{ $t('cancel') }}
                  </FlotoLink>
                </div>
              </FlotoScrollView>
            </MCol>
          </MRow>
        </FormRulesProvider>
      </FlotoContentLoader>
    </div>
  </FlotoFixedView>
</template>

<script>
import { successToast } from '@motadata/ui'
import CurrencyPicker from '@components/data-picker/currency-picker'
import { authComputed } from '@state/modules/auth'
import { OrganizationComputed } from '@state/modules/organization'
import { PreferenceComputed } from '@state/modules/preference'
import { getFormApi } from '@modules/form/form-api'
import FormConsumer from '@components/form-consumer.vue'
import FormHelperProvider from '@components/form-helper/form-helper-provider.vue'
// import { getFormRulesApi } from '@modules/form-rules/form-rules-api'
import { getFieldByRequesterAccess } from '@modules/support-portal/helpers/field-access'
import {
  getServiceCatalogApi,
  createServiceRequestApi,
} from '../service-catalog-api'
import { SupportPortalConfigComputed } from '@state/modules/support-portal-config'
import { getFieldMappingApi } from '@state/modules/field-mapping/api'
import FormRulesProvider from '@components/providers/form-rules-provider/form-rules-provider'
import LinkAssetDrawer from '@modules/ticket/components/link-asset-drawer'
import { nestFields, flattenFields } from '@data/form'
import { TechnicianMethods } from '@state/modules/technician'
import {
  requesterField,
  requesterEmailField,
} from '../utils/requester-field-data'

export default {
  name: 'RequestService',
  components: {
    FormConsumer,
    FormRulesProvider,
    CurrencyPicker,
    LinkAssetDrawer,
    FormHelperProvider,
  },
  model: { event: 'change' },
  props: {
    backLink: { type: Object, default: undefined },
    createdLink: { type: Function, default: undefined },
    serviceId: { type: Number, default: undefined },
    // eslint-disable-next-line
    withSubmit: { type: Boolean, default: true },
    // eslint-disable-next-line
    useFormHelpterProvider: { type: Boolean, default: true },
  },
  data() {
    return {
      service: {},
      form: {},
      processing: false,
      loading: true,
      request: {},
      formRules: [],
      selectedAssets: [],
      serviceCatalogFieldMapping: {},
    }
  },
  computed: {
    ...authComputed,
    ...OrganizationComputed,
    ...PreferenceComputed,
    ...SupportPortalConfigComputed,
    sercieCatalogCustomFields() {
      const formFields = this.isPortalLogin
        ? nestFields(
            flattenFields(this.form.fields || []).filter(
              (f) =>
                f.requesterCanEdit ||
                (f.useOnPortal && f.attributes.systemManagedField) ||
                f.type === 'label' ||
                f.type === 'section' ||
                f.type === 'api' ||
                f.type === 'dynamic'
            )
          )
        : this.form.fields
      return formFields
    },
    serviceFormFields() {
      const enablePriorityMatrix =
        this.tenantPrefrences.EnablePriorityMatrix &&
        this.tenantPrefrences.EnablePriorityMatrix.value
      let requesterFieldData = {}
      if (
        !this.loggedIn ||
        (!this.isPortalLogin && this.loggedIn) ||
        (this.loggedIn &&
          this.isPortalLogin &&
          this.allowGuestToRequestForService)
      ) {
        requesterFieldData = {
          ...requesterEmailField(),
          ...(!this.loggedIn && this.isPortalLogin
            ? { title: this.$tc('requester_email_field') }
            : {}),
          attributes: {
            widthClass: 'w-full',
            inputWidth: 40,
            disabled:
              this.loggedIn && this.isPortalLogin
                ? !this.allowRequesterToCreateRequestBehalfOfOtherRequester
                : false,
            showHint: !this.isPortalLogin,
          },
          requesterCanEdit:
            this.loggedIn && this.isPortalLogin
              ? this.allowRequesterToCreateRequestBehalfOfOtherRequester
              : true,
          ...(this.isPortalLogin
            ? { defaultValue: (this.user || {}).name }
            : {}),
        }
      } else {
        requesterFieldData = {
          ...requesterField(),
          attributes: {
            widthClass: 'w-full',
            inputWidth: 40,
            disabled:
              this.isPortalLogin &&
              !this.allowRequesterToCreateRequestBehalfOfOtherRequester,
          },
          requesterCanEdit: this.isPortalLogin
            ? this.allowRequesterToCreateRequestBehalfOfOtherRequester
            : true,
          ...(this.isPortalLogin ? { defaultValue: (this.user || {}).id } : {}),
        }
      }
      let serviceCatalogData = [
        requesterFieldData,
        ...(this.sercieCatalogCustomFields || []),
      ]
      if (enablePriorityMatrix) {
        serviceCatalogData = serviceCatalogData.filter(
          (s) => s.inputType !== 'priority'
        )
      }
      if (this.isPortalLogin) {
        // return (this.form.fields || []).filter((f) => f.useOnPortal)
        return getFieldByRequesterAccess(
          serviceCatalogData.filter((f) => f.useOnPortal),
          this.user
        )
      }

      // serviceCatalogData = this.form.fields || []
      return serviceCatalogData
    },
  },
  created() {
    getServiceCatalogApi(
      this.serviceId || this.$route.params.id,
      undefined,
      this.isPortalLogin
    ).then(
      (data) => {
        this.service = data
        this.getForm()
      },
      () => this.$router.replace({ name: '404' })
    )
    // this.getFormRules()
  },
  methods: {
    ...TechnicianMethods,
    getForm() {
      getFormApi(
        this.$constants.SERVICE_CATALOG,
        this.service.id,
        undefined,
        this.isPortalLogin
      ).then((data) => {
        this.getFieldMapping(data.id)
        this.form = data
        if (!this.loggedIn) {
          const customAsSystemTechnicianField = flattenFields(
            data.fields || []
          ).find((field) => field.fieldReferenceType === 'technician')
          if (customAsSystemTechnicianField) {
            this.fetchTechnicians({ serviceCatalogId: this.service.id })
          }
        }
      })
    },
    getFieldMapping(formId) {
      if (!this.loggedIn) {
        this.loading = false
        return
      }
      getFieldMappingApi(this.$constants.SERVICE_CATALOG, {
        destinationFormId: formId,
      }).then((data) => {
        this.serviceCatalogFieldMapping = data
        this.loading = false
      })
    },
    // getFormRules() {
    //   return getFormRulesApi({
    //     moduleName: this.$constants.SERVICE_CATALOG,
    //     parentId: this.serviceId || this.$route.params.id,
    //     name: '',
    //   }).then((data) => {
    //     this.formRules = data.items.filter((i) => i.enabled)
    //   })
    // },
    submitForm() {
      if (this.serviceFormFields.length) {
        return this.$refs.formRef.submit()
      }
      return this.handleFormSubmitted({})
    },
    handleFormSubmitted(request) {
      this.processing = true
      createServiceRequestApi(
        this.service.id,
        {
          ...request,
          linkAssetIds: this.selectedAssets.map((a) => `${a.id}:${a.model}`),
        },
        this.isPortalLogin
      )
        .then((data) => {
          if (this.loggedIn) {
            if (data.requesterId === (this.user || {}).id) {
              this.$router.push(
                this.createdLink
                  ? this.createdLink(data.id)
                  : this.$modules.getModuleRoute('ticket', 'view', {
                      params: {
                        id: data.id,
                        ticketType: this.$constants.REQUEST,
                      },
                    })
              )
            } else {
              if (!this.isPortalLogin) {
                this.$router.push(
                  this.$modules.getModuleRoute('ticket', 'view', {
                    params: {
                      id: data.id,
                      ticketType: this.$constants.REQUEST,
                    },
                  })
                )
              } else {
                this.$router.push(this.$currentModule.getRoute('my-requests'))
              }
            }
          } else {
            this.$router.push(this.$currentModule.getRoute())
            successToast(
              this.$t('created_successfully', {
                resource: `${this.$tc('service')} ${this.$tc(
                  this.$constants.REQUEST,
                  1
                )} (${data.name})`,
              })
            )
          }
        })
        .finally(() => (this.processing = false))
    },
    handleLinkAssetSelectionChange(items) {
      this.selectedAssets = items
      this.$emit('change', {
        ...this.value,
        linkAssetIds: items.map((a) => `${a.id}:${a.model}`),
      })
    },
  },
}
</script>
