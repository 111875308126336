var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoFixedView',[_c('FlotoPageHeader',{staticClass:"px-4",attrs:{"back-link":_vm.backLink ||
      _vm.$currentModule.getRoute('request', {
        params: Object.assign({}, (_vm.$route.params.searchTerm
            ? { searchTerm: _vm.$route.params.searchTerm }
            : {})),
      }),"use-divider":"","title":_vm.service.name || ((_vm.$tc('request')) + " " + (_vm.$tc('service')))},scopedSlots:_vm._u([{key:"back-button",fn:function(){return [_vm._t("back-button")]},proxy:true}],null,true)}),_c('div',{staticClass:"min-h-0 mt-3"},[_c('FlotoContentLoader',{staticClass:"h-100",attrs:{"loading":_vm.loading}},[_c('FormRulesProvider',{key:_vm.service.id,attrs:{"module-name":_vm.$constants.SERVICE_CATALOG,"parent-resource-id":_vm.service.id,"custom-fields":_vm.serviceFormFields}},[_c('MRow',{staticClass:"w-full min-h-0"},[_c('MCol',{attrs:{"auto-size":""}},[_c('div',{staticClass:"pl-6 pr-4"},[_c('img',{attrs:{"src":_vm.service.image,"width":"150","height":"150"}})])]),_c('MCol',{staticClass:"flex flex-col h-100 flex-1"},[_c('FlotoScrollView',[_c('h4',{staticClass:"text-primary font-semibold"},[_vm._v(_vm._s(_vm.service.name))]),(
                  (!_vm.isPortalLogin && _vm.service.amount > 0) ||
                  (_vm.isPortalLogin && _vm.service.showInCustomerPortalAmount)
                )?_c('div',{staticClass:"text-neutral flex"},[_vm._v(" "+_vm._s(_vm.$tc('cost'))+": "+_vm._s(_vm.service.amount)+" "),_c('CurrencyPicker',{attrs:{"text-only":"","disabled":""},model:{value:(_vm.service.currencyId),callback:function ($$v) {_vm.$set(_vm.service, "currencyId", $$v)},expression:"service.currencyId"}})],1):_vm._e(),_c('div',{staticClass:"flex flex-col"},[_vm._v(" "+_vm._s(_vm.service.serviceDescription)+" ")]),(_vm.serviceFormFields.length)?[_c('MDivider'),_c('h5',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$t('additional_info')))])]:_vm._e(),_c(_vm.useFormHelpterProvider ? 'FormHelperProvider' : 'div',{tag:"component"},[(_vm.serviceFormFields.length)?_c('FormConsumer',{ref:"formRef",attrs:{"with-submit":_vm.withSubmit,"form-fields":_vm.serviceFormFields,"module-name":_vm.$constants.SERVICE_CATALOG,"external-data":{
                    requestType: 'service_request',
                    serviceCatalogId: _vm.$route.params.id,
                  },"apply-form-rules":"","allow-field-mapping":"","service-catalog-field-mapping":_vm.serviceCatalogFieldMapping,"service-catalog-id":_vm.$route.params.id},on:{"change":function($event){return _vm.$emit('change', $event)},"submit":_vm.handleFormSubmitted},scopedSlots:_vm._u([{key:"submit",fn:function(){return [_c('span')]},proxy:true},{key:"reset",fn:function(){return [_c('span')]},proxy:true}],null,false,1976021544),model:{value:(_vm.request),callback:function ($$v) {_vm.request=$$v},expression:"request"}}):_vm._e()],1),(_vm.service.allowRequesterToLinkMyAsset)?_c('LinkAssetDrawer',{staticClass:"ml-2",attrs:{"module-name":_vm.$constants.ASSET,"default-selected-assets":_vm.selectedAssets,"service-catalog-id":_vm.$route.params.id},on:{"change":_vm.handleLinkAssetSelectionChange}}):_vm._e(),(_vm.withSubmit)?_c('MDivider'):_vm._e(),(_vm.withSubmit)?_c('div',{staticClass:"flex justify-end mb-4"},[_c('MButton',{staticClass:"mx-1",attrs:{"id":"submit-request","loading":_vm.processing},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.$t('submit_request'))+" ")]),_c('FlotoLink',{staticClass:"ml-4",attrs:{"to":_vm.backLink || _vm.$currentModule.getRoute('request'),"variant":"default","as-button":""}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1):_vm._e()],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }