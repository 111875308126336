import Moment from 'moment'
import Highcharts from 'highcharts'
// Utility functions
const dateFormat = Highcharts.dateFormat
const defined = Highcharts.defined
const isObject = Highcharts.isObject
const reduce = Highcharts.reduce
export default function transformDataToGanttChart(
  data,
  resource,
  milestoneId,
  technicianOptions,
  callBackFn
) {
  let seriesData = []
  data.forEach((item) => {
    const isSummary = item.type === 'summary_task'
    const isMilestone = item.taskTypeId === milestoneId
    seriesData.push({
      name: isSummary
        ? item.name
        : `${item.name}: ${item.subject} (${
            (technicianOptions.find((o) => o.key === item.technicianId) || {})
              .name
          })`,
      assignee: (
        technicianOptions.find((o) => o.key === item.technicianId) || {}
      ).name,
      id: isSummary ? `summary-${item.id}` : `task-${item.taskId}`,
      taskId: item.taskId,
      type: item.type,
      start: (item.timeRange || {}).startTime,
      end: (item.timeRange || {}).endTime,
      completed: !isMilestone
        ? {
            amount: item.completion / 100,
          }
        : undefined,
      color:
        Moment().unix() * 1000 - (item.timeRange || {}).endTime > 0
          ? '#ff0000'
          : item.color,
      milestone: isMilestone,
      dependency: (item.predecessors || []).map((i) => `task-${i}`),
      task: {
        ...item,
      },
      overdue: Moment().unix() * 1000 - (item.timeRange || {}).endTime > 0,
    })
    if ((item.children || []).length > 0) {
      const children = (item.children || []).map((i) => {
        return {
          name: `${i.name}: ${i.subject} (${
            (technicianOptions.find((o) => o.key === i.technicianId) || {}).name
          })`,
          assignee: (
            technicianOptions.find((o) => o.key === i.technicianId) || {}
          ).name,
          id: `task-${i.taskId}`,
          taskId: i.taskId,
          type: i.type,
          parent: `summary-${item.id}`,
          start: (i.timeRange || {}).startTime,
          end: (i.timeRange || {}).endTime,
          completed: {
            amount: i.completion / 100,
          },
          color:
            Moment().unix() * 1000 - (i.timeRange || {}).endTime > 0
              ? '#ff0000'
              : i.color,
          milestone: isMilestone,
          dependency: (i.predecessors || []).map((i) => `task-${i}`),
          task: {
            ...i,
          },
          overdue: Moment().unix() * 1000 - (i.timeRange || {}).endTime > 0,
        }
      })
      seriesData = [...seriesData, ...children]
    }
  })
  const config = {
    // title: {
    //   text: `Gantt View - ${resource.name}: ${resource.displayName}`,
    // },
    credits: {
      enabled: false,
    },
    navigator: {
      enabled: true,
      liveRedraw: true,
      series: {
        type: 'gantt',
        pointPlacement: 0.5,
        pointPadding: 0.25,
      },
      yAxis: {
        min: 0,
        max: 3,
        reversed: true,
        categories: [],
      },
    },
    scrollbar: {
      enabled: true,
    },
    rangeSelector: {
      enabled: true,
      selected: 5,
    },
    xAxis: {
      currentDateIndicator: true,
      min: resource.startDate,
      max: resource.endDate,
    },
    series: [
      {
        name: `${resource.name}: ${resource.displayName}`,
        enableMouseTracking: true,
        events: {
          click: (event) => {
            callBackFn(event)
          },
        },
        className: 'cursor-pointer',
        data: seriesData,
        tooltip: {
          pointFormatter: function() {
            var point = this
            var format = '%d/%m/%Y %H:%M:%S'
            var options = point.options
            var completed = options.completed
            var amount = isObject(completed) ? completed.amount : completed
            var status = ((amount || 0) * 100).toFixed(0) + '%'
            var lines

            lines = [
              {
                value: point.name,
                style: 'font-weight: bold;',
              },
              {
                title: 'Start',
                value: dateFormat(format, point.start),
              },
              {
                title: 'End',
                value: dateFormat(format, point.end),
              },
              {
                visible: !options.milestone,
                title: 'Completed',
                value: status,
              },
              {
                title: 'Assignee',
                value: options.assignee || 'unassigned',
              },
              {
                visible: point.overdue,
                title: 'Overdue',
                value: 'True',
                style: 'color: red',
              },
            ]

            return reduce(
              lines,
              function(str, line) {
                var s = ''
                var style = defined(line.style)
                  ? line.style
                  : 'font-size: 0.8em;'
                if (line.visible !== false) {
                  s =
                    '<span style="' +
                    style +
                    '">' +
                    (defined(line.title) ? line.title + ': ' : '') +
                    (defined(line.value) ? line.value : '') +
                    '</span><br/>'
                }
                return str + s
              },
              ''
            )
          },
        },
      },
    ],
  }
  return config
}
