<template>
  <FormRulesProvider :module-name="$constants.TASK">
    <FlotoContentLoader :loading="loading">
      <MRow :gutter="0">
        <MCol v-if="disabled" :size="12">
          <MInput
            v-model="searchTerm"
            class="search-box"
            :placeholder="$t('search')"
            @update="getTasks"
          >
            <template v-slot:prefix>
              <MIcon name="search" />
            </template>
          </MInput>
          <MDropdown
            :options="filterOptions"
            :value="(filterType || {}).value"
            @change="handleFilterChange($event)"
          >
            <template v-slot:trigger>
              <MButton variant="transparent" :shadow="false" class="pr-1">
                {{
                  filterType
                    ? filterOptions.find((t) => t.key === filterType).text
                    : $t('all')
                }}
                <MIcon name="filter" size="lg" />
              </MButton>
            </template>
          </MDropdown>
        </MCol>
        <MCol v-if="!disabled" :size="12" class="text-right">
          <MTooltip>
            <template v-slot:trigger>
              <MIcon
                name="sync"
                class="mr-2 cursor-pointer text-neutral-light"
                @click="refresh"
              />
            </template>
            {{ $t('refresh') }}
          </MTooltip>
          <MButton
            id="add-task-btn"
            variant="primary"
            @click="() => handleCreate('task')"
          >
            {{ $t('add') }} {{ $tc('task') }}
          </MButton>
          <MButton
            id="add-summary-task-btn"
            class="mx-2"
            variant="primary"
            @click="() => handleCreate('summary_task')"
          >
            {{ $t('add') }} {{ $tc('summary_task') }}
          </MButton>
          <MButton
            id="add-milestone-btn"
            variant="primary"
            @click="() => handleCreate('milestone')"
          >
            {{ $t('add') }} {{ $tc('milestone') }}
          </MButton>
        </MCol>
      </MRow>
      <MRow :gutter="0">
        <MCol :size="12">
          <HierarchyExplorer
            class="no-tree-line"
            :sortable="!disabled"
            :max-level="2"
            :default-value="data"
            :add-fn="addFn"
            :remove-fn="removeFn"
            :allow-drop="!disabled"
            :bulk-update-fn="bulkUpdateFn"
            :scroll-sensitivity="200"
            :force-fallback="true"
            @move="handleChangeOrder"
            @add="handleChangeParent"
            @remove="handleRemoveItem"
          >
            <template v-slot="{ item, canAdd, toggle, remove }">
              <ProjectTaskItem
                :item="item"
                :tasks="tasks"
                :can-add="canAdd"
                :toggle-expand="toggle"
                :disabled="disabled"
                :task-type-options="taskTypeOptions"
                :resource-id="resourceId"
                @add-action="handleCreate"
                @update="updateFn"
                @edit="handleEdit"
                @remove="handleRemoveProjectTaskItem(item, remove)"
              />
            </template>
          </HierarchyExplorer>
        </MCol>
      </MRow>
      <FlotoDrawerForm
        :open="showCreateDrawer"
        @cancel="hideDrawer"
        @submit="createFn"
      >
        <template v-slot:header>
          {{
            formData.taskId
              ? `${$tc('edit')}: ${formData.name}`
              : `${$tc('add')} ${$tc(type)}`
          }}
        </template>
        <ProjectTaskForm
          :task="formData"
          :resource="resource"
          :type="type"
          @change="handleChangeFormData"
        />
        <template v-slot:actions="{ hide, submit }">
          <MButton
            id="update-add-btn"
            class="mr-2"
            :loading="processing"
            @click="submit"
          >
            {{ formData.taskId ? $tc('update') : $tc('add') }}
          </MButton>
          <MButton id="cancel-btn" variant="default" @click="hide">
            {{ $t('cancel') }}
          </MButton>
        </template>
      </FlotoDrawerForm>
    </FlotoContentLoader>
  </FormRulesProvider>
</template>

<script>
import FormRulesProvider from '@components/providers/form-rules-provider/form-rules-provider'
import CloneDeep from 'lodash/cloneDeep'
import HierarchyExplorer from '@components/sortable/hierarchy-explorer'
import { defaultTask } from '@data/task'
import ProjectTaskForm from './project-task-form'
import { TaskComputed } from '@state/modules/task'
import { findValuePath } from '@data/recursive'
import {
  createTaskApi,
  updateTaskApi,
  deleteTaskApi,
  getPlanningTasksApi,
  createSummaryTaskApi,
  bulkUpdateParentTaskApi,
  updateTasksApi,
  deleteSummaryTaskApi,
  updateSummaryTaskApi,
} from './api'
import ProjectTaskItem from './project-task-item'
export default {
  name: 'ProjectTaskList',
  components: {
    FormRulesProvider,
    ProjectTaskForm,
    HierarchyExplorer,
    ProjectTaskItem,
  },
  props: {
    resourceId: { type: [String, Number], required: true },
    resource: { type: Object, required: true },
    moduleName: {
      type: String,
      default() {
        return this.$constants.PROJECT
      },
    },
    disabled: { type: Boolean, default: false },
  },
  data() {
    this.filterOptions = [
      { text: this.$tc('all'), key: 'all' },
      { text: `${this.$tc('task')} ${this.$tc('only')}`, key: 'task' },
      {
        text: `${this.$tc('milestone')} ${this.$tc('only')}`,
        key: 'milestone',
      },
      { text: `${this.$tc('unassigned')}`, key: 'unassigned' },
      { text: `${this.$tc('overdue')}`, key: 'overdue' },
    ]
    return {
      loading: true,
      processing: false,
      formData: {},
      data: [],
      tasks: [],
      type: 'task',
      searchTerm: '',
      showCreateDrawer: false,
      level1Processing: false,
      level2Processing: false,
      filterType: undefined,
    }
  },
  computed: {
    ...TaskComputed,
    defaultTaskData() {
      const task = CloneDeep(defaultTask)
      if (this.type === 'milestone') {
        const taskTypeId = (
          this.taskTypeOptions.find((t) => t.systemName === 'Milestone') || {}
        ).id
        return {
          ...task,
          taskTypeId,
        }
      }
      return task
    },
    milestoneId() {
      return (
        this.taskTypeOptions.find((t) => t.systemName === 'Milestone') || {}
      ).id
    },
  },
  created() {
    this.getTasks()
  },
  methods: {
    handleFilterChange(option) {
      this.filterType = option.key === 'all' ? undefined : option.key
      this.getTasks()
    },
    handleAddAction(type, item) {
      this.type = type
      const task = CloneDeep(this.defaultTaskData)
      this.formData = {
        ...task,
        summaryTaskId: item.taskId,
      }
      this.showCreateDrawer = true
    },
    handleCreate(type, parentItem) {
      this.type = type
      if (type === 'summary_task') {
        const task = CloneDeep(this.defaultTaskData)
        this.formData = {
          ...task,
        }
        this.showCreateDrawer = true
        return
      }
      this.$router.push(
        this.$modules.getModuleRoute('my-tasks', 'create', {
          query: {
            moduleName: this.moduleName,
            refModel: this.moduleName,
            refId: this.resourceId,
            backLink: JSON.stringify(
              this.$modules.getModuleRoute('project', 'edit', {
                params: { id: this.resourceId },
              })
            ),
            ...(type === 'milestone' ? { taskType: this.type } : {}),
            ...(parentItem && parentItem.taskId
              ? { summaryTaskId: parentItem.taskId }
              : {}),
          },
        })
      )
    },
    handleEdit(data) {
      const item = CloneDeep(data)
      const type =
        item.type !== 'summary_task'
          ? (
              this.taskTypeOptions.find((t) => t.systemName === 'Milestone') ||
              {}
            ).id === item.taskTypeId
            ? 'milestone'
            : 'task'
          : 'summary_task'
      this.type = type
      if (item.type === 'summary_task') {
        this.formData = {
          ...item,
        }
        this.showCreateDrawer = true
        return
      }
      this.$router.push(
        this.$modules.getModuleRoute('my-tasks', 'edit', {
          params: { id: item.taskId },
          query: {
            moduleName: this.moduleName,
            refModel: this.moduleName,
            refId: this.resourceId,
            backLink: JSON.stringify(
              this.$modules.getModuleRoute('project', 'edit', {
                params: { id: this.resourceId },
              })
            ),
          },
        })
      )
    },
    hideDrawer() {
      this.showCreateDrawer = false
      this.formData = {}
    },
    handleChangeFormData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    handleRemoveProjectTaskItem(item, removeFn) {
      return removeFn(item).then(() => this.refresh())
    },
    getTasks() {
      return getPlanningTasksApi(
        {
          moduleName: this.moduleName,
          filter: this.searchTerm,
          ...(this.filterType ? { [this.filterType]: true } : {}),
          milestoneId: this.milestoneId,
          serchable: this.disabled,
        },
        this.resourceId
      ).then((data) => {
        this.data = (data || {}).hierarchy || []
        this.tasks = (data || {}).items || []
        this.$emit('task-exist', ((data || {}).items || []).length > 0)
        this.loading = false
      })
    },
    handleChangeOrder(event) {
      const updatedList = event.items.map((item, index) => ({
        ...item,
        order: index + 1,
      }))
      this.bulkUpdateTasks(updatedList, event.parent)
    },
    handleChangeParent(eventInfo, parent) {
      let updatedResult = []
      const item = eventInfo.item
      if (item.type === 'summary_task') {
        return this.refreshList()
      }
      if (!parent) {
        updatedResult = [
          ...this.data.slice(0, eventInfo.index),
          { ...item, summaryTaskId: 0 },
          ...this.data.slice(eventInfo.index),
        ]
      } else {
        updatedResult = [
          ...parent.children.slice(0, eventInfo.index),
          { ...item, summaryTaskId: parent.taskId },
          ...parent.children.slice(eventInfo.index),
        ]
      }
      const path = findValuePath(updatedResult, item.id)
      if (path.length >= 3 && (item.child || []).length) {
        this.$errorNotification({
          message: 'Error',
          description: 'Max Level error',
        })
        return
      }
      this.bulkUpdateTasks(updatedResult, parent)
    },
    handleRemoveItem(eventInfo, parent) {
      const item = eventInfo.item
      if (item.type === 'summary_task') {
        return this.refreshList()
      }
      let updatedResult = []
      if (!parent) {
        updatedResult = [
          ...this.data.slice(0, eventInfo.index),
          ...this.data.slice(eventInfo.index + 1),
        ]
      } else {
        updatedResult = [
          ...parent.children.slice(0, eventInfo.index),
          ...parent.children.slice(eventInfo.index + 1),
          { ...item, remove: true },
        ]
      }
      this.bulkUpdateTasks(updatedResult, parent)
    },
    createFn() {
      const data = this.formData
      this.processing = true
      if (this.type === 'task' || this.type === 'milestone') {
        return this.createTask({ ...data })
          .then(() => {
            this.hideDrawer()
          })
          .finally(() => {
            this.processing = false
          })
      }
      if (this.type === 'summary_task') {
        return this.createSummaryTask(data)
          .then(() => {
            this.hideDrawer()
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    createTask(data) {
      if (data.taskId) {
        return updateTaskApi(this.moduleName, this.resourceId, {
          ...data,
          id: data.taskId,
          moduleName: this.moduleName,
        }).then(() => this.refresh())
      }
      return createTaskApi(this.moduleName, this.resourceId, {
        ...data,
        moduleName: this.moduleName,
      }).then(() => this.refresh())
    },
    createSummaryTask(data) {
      if (data.taskId) {
        return updateSummaryTaskApi(this.moduleName, this.resourceId, {
          ...data,
          id: data.taskId,
          moduleName: this.moduleName,
        }).then(() => this.refresh())
      }
      return createSummaryTaskApi(this.moduleName, this.resourceId, {
        ...data,
        moduleName: this.moduleName,
      }).then(() => this.refresh())
    },

    updateFn(data) {
      if (data.type === 'summary_task') {
        return updateSummaryTaskApi(this.moduleName, this.resourceId, {
          ...data,
          id: data.taskId,
          moduleName: this.moduleName,
        }).then(() => this.refresh())
      }
      return updateTaskApi(this.moduleName, this.resourceId, {
        ...data,
        id: data.taskId,
        moduleName: this.moduleName,
      }).then(() => this.refresh())
    },
    bulkUpdateTasks(items, parent) {
      if (!parent) {
        this.level1Processing = true
        return bulkUpdateParentTaskApi(
          this.moduleName,
          this.resourceId,
          items
        ).finally(() => {
          this.level1Processing = false
          this.refreshList()
        })
      } else {
        this.level2Processing = true
        return updateTasksApi(
          this.moduleName,
          this.resourceId,
          items.map((item) => ({
            ...item,
            summaryTaskId: item.remove ? 0 : parent.taskId,
          }))
        ).finally(() => {
          this.level2Processing = false
          this.refreshList()
        })
      }
    },
    refreshList() {
      if (!(this.level1Processing && this.level2Processing)) {
        this.refresh()
      }
    },
    bulkUpdateFn(items) {
      return Promise.resolve(items)
    },
    refresh() {
      this.getTasks()
    },
    addFn(data, parent) {
      return Promise.resolve([data])
    },
    removeFn(item) {
      if (item.type === 'summary_task') {
        return deleteSummaryTaskApi(this.moduleName, this.resourceId, {
          id: item.taskId,
        })
      }
      return deleteTaskApi(this.moduleName, this.resourceId, {
        id: item.taskId,
      })
    },
  },
}
</script>
